import AuthModal from "@/components/AuthModal";
import Modal from "@/components/Modal";
import { useStores } from "@/hooks/useStores";
import state from "@/state";
import "@/styles/globals.css";
import "@/styles/index.scss";
import { GoogleTagManager } from "@next/third-parties/google";
import { AnimatePresence } from "framer-motion";
import "intersection-observer";
import { observer } from "mobx-react";
import { SessionProvider } from "next-auth/react";
import { useRouter } from "next/router";
import Script from "next/script";
import NProgress from "nprogress";
import { useEffect } from "react";

NProgress.configure({ easing: "easeOut", speed: 750 });

const MyApp = ({ Component, pageProps }) => {
  const { uiStore } = useStores();

  const router = useRouter();

  /*
		Handle hubspot tracking
	*/

  useEffect(() => {
    const handleRouteChange = (url) => {
      if (window._hsq) {
        window._hsq.push(["setPath", url]);
        window._hsq.push(["trackPageView"]);
      }
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  // Close menu on nav change
  useEffect(() => {
    const routeChangeEnd = () => {
      state.menu.isOpen = false;
    };

    router.events.on("routeChangeComplete", routeChangeEnd);
    router.events.on("routeChangeError", routeChangeEnd);
  }, [router]);

  // Close auth modal on nav change
  useEffect(() => {
    const routeChangeEnd = () => {
      uiStore.closeAuthModal();
    };

    router.events.on("routeChangeComplete", routeChangeEnd);
    router.events.on("routeChangeError", routeChangeEnd);
  }, [uiStore, router]);

  // Handle nprogress
  useEffect(() => {
    const routeChangeStart = () => {
      NProgress.start();
    };

    const routeChangeEnd = () => {
      NProgress.done();
      state.menu.isOpen = false;
    };

    router.events.on("routeChangeStart", routeChangeStart);
    router.events.on("routeChangeComplete", routeChangeEnd);
    router.events.on("routeChangeError", routeChangeEnd);
  }, [router]);

  const getLayout = Component.getLayout || ((page) => page);

  const closeAuthModal = () => {
    uiStore.setIsAuthModalActive(false);
  };

  return (
    <SessionProvider session={pageProps.session}>
      <>
        <Script id="iubenda-cs-script">{`var _iub = _iub || [];
					_iub.csConfiguration = {"askConsentAtCookiePolicyUpdate":true,"countryDetection":true,"enableFadp":true,"enableLgpd":true,"enableUspr":true,"lang":"en-GB","lgpdAppliesGlobally":false,"perPurposeConsent":true,"siteId":2169413,"whitelabel":false,"cookiePolicyId":62140473, "banner":{ "acceptButtonDisplay":true,"closeButtonRejects":true,"customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"position":"float-bottom-left","rejectButtonDisplay":true,"showPurposesToggles":true,"showTitle":false }};`}</Script>
        <Script
          type="text/javascript"
          src="https://cs.iubenda.com/autoblocking/2169413.js"
        ></Script>
        <Script
          type="text/javascript"
          src="//cdn.iubenda.com/cs/gpp/stub.js"
        ></Script>
        <Script
          type="text/javascript"
          src="//cdn.iubenda.com/cs/iubenda_cs.js"
          charset="UTF-8"
          async
        ></Script>
      </>
      <Script
        id="hs-script-loader"
        src="https://js.hs-scripts.com/6989652.js"
      />
      {getLayout(<Component {...pageProps} />)}
      <AnimatePresence>
        <Modal
          isOpen={uiStore.isAuthModalActive}
          onRequestClose={closeAuthModal}
        >
          <AuthModal />
        </Modal>
      </AnimatePresence>
      <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GTM_ID} />
    </SessionProvider>
  );
};

export default observer(MyApp);
