import style from "components/AuthModal.module.scss";
import Button from "components/Button";
import { useStores } from "hooks/useStores";
import { observer } from "mobx-react";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

const AuthModal = ({}) => {
  const { uiStore } = useStores();

  const router = useRouter();

  const CONTENT = {
    events: [
      {
        key: "partner",
        heading: "Work for a YCN partner?",
        caption:
          "Simply sign in to sign up for this pathway and any others that catch your eye.",
        label: "Sign in",
        href: `/auth/signin?destination=${
          uiStore.postAuthDestination || router.asPath
        }`,
      },
      {
        key: "sample",
        heading: "Keen to sample?",
        caption:
          "Drop us a line to join this session, or to pilot our platform with your team.",
        label: "Get in touch",
        href: "https://www.ycn.org/partnerships",
      },
    ],
    resources: [
      {
        key: "partner",
        heading: "Work for a YCN partner?",
        caption:
          "Simply sign in to access this resource and any others that catch your eye.",
        label: "Sign in",
        href: `/auth/signin?destination=${
          uiStore.postAuthDestination || router.asPath
        }`,
      },
      {
        key: "sample",
        heading: "Keen to sample?",
        caption:
          "Drop us a line to access this resource, or to pilot our platform with your team.",
        label: "Get in touch",
        href: "https://www.ycn.org/partnerships",
      },
    ],
  };

  return (
    <div className={style.root}>
      <div className={style.content}>
        <div className={style.header} />
        <div className={style.panels}>
          {CONTENT[uiStore.authModalType].map((panel) => {
            return (
              <div key={panel.key} className={style.panel}>
                <h4 className={style.heading}>{panel.heading}</h4>
                <div className={style.caption}>{panel.caption}</div>
                <Link href={panel.href} className={style.action}>
                  <Button
                    label={panel.label}
                    backgroundColor="#199cd9"
                    textColor="#fff"
                  />
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default observer(AuthModal);
