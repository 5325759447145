import style from "components/Button.module.scss";
import React from "react";
import { ArrowRight } from "react-feather";

const Button = ({
  label,
  textColor = "#2f3946",
  backgroundColor = "transparent",
}) => {
  const borderColor = backgroundColor === "transparent" ? textColor : null;

  return (
    <div
      className={style.root}
      style={{ color: textColor, borderColor, backgroundColor }}
    >
      <div className={style.label}>{label}</div>
      <div className={style.icon}>
        <ArrowRight strokeWidth={1.5} size={18} />
      </div>
    </div>
  );
};

export default Button;
