import { makeAutoObservable } from "mobx";
import { enableStaticRendering } from "mobx-react";

// eslint-disable-next-line react-hooks/rules-of-hooks
enableStaticRendering(typeof window === "undefined");

class UIStore {
  constructor() {
    makeAutoObservable(this);
  }

  /*
		Content Overlay
	*/
  isContentOverlayActive = false;

  setIsContentOverlayActive = (value) => {
    this.isContentOverlayActive = value;
  };

  contentOverlay = null;

  setContentOverlay = (value) => {
    this.contentOverlay = value;
  };

  closeContentOverlay = () => {
    this.setIsContentOverlayActive(false);
    this.setContentOverlay(null);
  };

  /*
		Authentication modal
	*/

  authModalType = "resources"; // "resources" / "events"

  setAuthModalType = (value) => {
    this.authModalType = value;
  };

  isAuthModalActive = false;

  setIsAuthModalActive = (value) => {
    this.isAuthModalActive = value;
  };

  closeAuthModal = () => {
    this.setIsAuthModalActive(false);
  };

  postAuthDestination = null;

  setPostAuthDestination = (value) => {
    this.postAuthDestination = value;
  };
}

export default UIStore;
