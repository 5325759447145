import { proxy } from "valtio";

const state = proxy({
  search: { isOpen: false },
  header: {
    offsetTop: false,
    isPinned: true,
    isTop: true,
    theme: "dark", // may be "light" or "dark"
  },
  menu: {
    isOpen: false,
  },
});

export default state;
