import style from "components/Modal.module.scss";
import React, { useRef, useState } from "react";
import ReactModal from "react-modal";

ReactModal.setAppElement("#__next");

const Modal = ({
  children,
  isClosable = true,
  isOpen = false,
  onRequestClose = () => null,
}) => {
  const ref = useRef();

  const handleRequestClose = () => {
    if (isClosable) onRequestClose();
  };

  return (
    <ReactModal
      id="modal"
      closeTimeoutMS={250}
      isOpen={isOpen}
      onRequestClose={handleRequestClose}
      className={{
        base: style.content,
        afterOpen: style.content___afterOpen,
        beforeClose: style.content___beforeClose,
      }}
      overlayClassName={{
        base: style.overlay,
        afterOpen: style.overlay___afterOpen,
        beforeClose: style.overlay___beforeClose,
      }}
      portalClassName={style.portal}
      bodyOpenClassName={style.body___modalOpen}
      htmlOpenClassName={style.html___modalOpen}
      contentRef={(contentRef) => {
        ref.current = contentRef;
      }}
    >
      <div className={style.wrapper}>
        <div className={style.buttons}>
          <CloseButton onRequestClose={onRequestClose} />
        </div>
        <div className={style.children}>{children}</div>
      </div>
    </ReactModal>
  );
};

const CloseButton = ({ onRequestClose }) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <button
      className={style.close}
      type="button"
      onClick={onRequestClose}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      aria-label="Close modal"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="butt"
        strokeLinejoin="round"
      >
        <line x1="18" y1="6" x2="6" y2="18" />
        <line x1="6" y1="6" x2="18" y2="18" />
      </svg>
    </button>
  );
};

export default Modal;
